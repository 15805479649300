import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../common/Sidebar";
import { HashLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { fetchPosts, changeLimit, changePage, bannedPost } from "../../apps/post";
import ReactPaginate from "react-paginate";

const Reported = () => {
    //const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { posts, total, limit, page } = useSelector((state) => state.post);
    const [searchTerm, setSearchTerm] = useState("");
    const [status, setStatus] = useState("");
    const [reported, setReported] = useState("reported");
    const pageCount = useMemo(() => {
        const pageCounter = Math.ceil(total / limit);
        return pageCounter;
    }, [total, limit]);

    useEffect(() => {
        getPosts();
    }, []);

    const getPosts = async () => {
        const resp = await dispatch(fetchPosts({
            limit: limit,
            page: page,
            reported: reported,
            keyword: searchTerm,
        }));
    };

    const deleteMe = async (id) => {
        try {
            const deleteUser = await axios.delete(
                `${process.env.REACT_APP_API_URL_LOCAL}/users/delete/${id}`
            );

            if (deleteUser.status == 200) {
                toast.success("User deleted");
            }
        } catch (error) {
            if (error.response.status) {
                toast.error("Something went wrong");
            }
        }
    };
    const navigateToAddPage = () => {
        navigate("/users/Add");
    };

    const handlePageClick = async (data) => {
        await dispatch(changePage(data.selected + 1));
        await dispatch(
            fetchPosts({
                limit: limit,
                page: data.selected + 1,
                keyword: searchTerm,
                reported: reported
            })
        );
    };

    const limitHandler = async (e) => {
        const limit = await dispatch(changeLimit(e.target.value));

        await dispatch(
            fetchPosts({
                limit: e.target.value,
                page: 1,
                keyword: searchTerm,
                reported: reported
            })
        );
    };

    const handleSearchOption = async (keyword) => {
        setSearchTerm(keyword);
        await dispatch(
            fetchPosts({
                limit: limit,
                page: 1,
                keyword: keyword,
                reported: reported
            })
        );
    };


    const handleStatus = async (postId, status) => {
        const resp = await dispatch(
            bannedPost({
                post_id: postId,
                status: status
            })
        );
        if (resp?.payload?.code === 200) {
            toast.success('Status updated successfully');
            getPosts();
        }
    }

    return (
        <>
            <Sidebar />
            <div className="card">
                <div className="card-header bg-white card-header-wrapper">
                    <div className="d-flex justify-content-between align-items-center">
                        <h3 className="mb-0">Reported Posts</h3>
                        <div className="d-flex align-items-center justify-content-end gap-4">
                            <div className="searchBox">
                                <input className="form-control" placeholder="Search" onChange={(e) => handleSearchOption(e.target.value)} value={searchTerm} />
                            </div>
                        </div>
                    </div>
                </div>
                {!posts.length > 0 ? (
                    <div className="spinner">
                        <HashLoader color="#757575" size={75} loading={true} />
                    </div>
                ) : (
                    <>
                        <div className="table-responsive">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Post Type</th>
                                        <th>User</th>
                                        <th>Likes</th>
                                        <th>Comments</th>
                                        <th>Status</th>
                                        <th>IsReported</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {total > 0 ?
                                        posts.map((item, i) => {
                                            return (
                                                <tr key={item?._id}>
                                                    <td>{item?.title}</td>
                                                    <td>{item?.postType}</td>
                                                    <td>{item?.user?.firstName} {item?.user?.lastName ?? ''}</td>
                                                    <td>{item?.totalLikes}</td>
                                                    <td>{item?.comments?.length}</td>
                                                    <td>{item?.status ? 'Active' : 'Inactive'}</td>
                                                    <td>{item?.isReported ? 'Yes' : 'No'}</td>
                                                    <td>
                                                        <button
                                                            className="btn action_btn"
                                                            onClick={() => navigate('/post/' + item._id)}
                                                        >
                                                            <i className="fa fa-eye"></i>
                                                        </button>
                                                        <button
                                                            className="btn action_btn"
                                                            onClick={() => handleStatus(item._id, !item?.status)}
                                                        >
                                                            {
                                                                item?.status ?
                                                                    <i className="fa fa-ban" title="Click to banned post"></i> :
                                                                    <i className="fa fa-check" title="Click to remove banned"></i>
                                                            }
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        : <tr><td colSpan="8" className="text-center">No record found</td></tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                        {total > 0 &&
                            <div className="d-flex justify-content-between align-items-center px-3 mb-2">
                                <div>
                                    <select className="form-select" onChange={limitHandler} value={limit}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                                <div>
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        breakLabel={"..."}
                                        pageCount={pageCount === 1 ? 1 : pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"pagination_prev"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"pagination_next"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            </div>
                        }
                    </>
                )}
            </div>

        </>
    );
};
export default Reported;
