import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../common/Sidebar";
import { HashLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { fetchPayments, changeLimit, changePage } from "../../apps/payment";
import ReactPaginate from "react-paginate";
import moment from "moment";

const Payments = () => {
  //const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { payments, total, limit, page } = useSelector((state) => state.payment);
  const [searchTerm, setSearchTerm] = useState("");
  const pageCount = useMemo(() => {
    const pageCounter = Math.ceil(total / limit);
    return pageCounter;
  }, [total, limit]);

  useEffect(() => {
    getPayments();
  }, []);

  const getPayments = async () => {
    const resp = await dispatch(fetchPayments({
      limit: limit,
      page: page,
      keyword: searchTerm
    }));
  };

  const deleteMe = async (id) => {
    try {
      const deleteUser = await axios.delete(
        `${process.env.REACT_APP_API_URL_LOCAL}/users/delete/${id}`
      );

      if (deleteUser.status == 200) {
        toast.success("User deleted");
      }
    } catch (error) {
      if (error.response.status) {
        toast.error("Something went wrong");
      }
    }
  };
  const navigateToAddPage = () => {
    navigate("/users/Add");
  };
  const navigateToEdit = (item) => {
    navigate("/users/Edit", { state: item });
  };

  const handlePageClick = async (data) => {
    await dispatch(changePage(data.selected + 1));
    await dispatch(
      fetchPayments({
        limit: limit,
        page: data.selected + 1,
        keyword: searchTerm
      })
    );
  };

  const limtHandler = async (e) => {
    const limit = await dispatch(changeLimit(e.target.value));

    await dispatch(
      fetchPayments({
        limit: e.target.value,
        page: 1,
        keyword: searchTerm
      })
    );
  };

  const handleSearchOption = async (keyword) => {
    setSearchTerm(keyword);
    await dispatch(
      fetchPayments({
        limit: limit,
        page: 1,
        keyword: keyword
      })
    );
  };

  return (
    <>
      <Sidebar />
      <div className="card">
        <div className="card-header bg-white card-header-wrapper">
          <div className="d-flex justify-content-between align-items-center">
            <h3>Payments</h3>
            <div className="searchBox">
              <input className="form-control" placeholder="Search" onChange={(e) => handleSearchOption(e.target.value)} value={searchTerm} />
            </div>
          </div>
          {/* <Button
          className="btn btn-primery px-4 py-2"
          onClick={navigateToAddPage}
        >
          Add
        </Button> */}
        </div>
        {!payments.length > 0 ? (
          <div className="spinner">
            <HashLoader color="#757575" size={75} loading={true} />
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr>
                    <th>Transaction ID</th>
                    <th>Product Type</th>
                    <th>User</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {total > 0 ?
                    payments.map((item, i) => {
                      return (
                        <tr key={item?._id}>
                          <td>{item?.txnId}</td>
                          <td>{item?.productType}</td>
                          <td>{item?.user?.firstName} {item?.user?.lastName ?? ''}</td>
                          <td>{item?.amount}</td>
                          <td>{item?.status}</td>
                          <td>{moment(item?.createdAt).format("MMMM DD, YYYY HH:mm A")}</td>
                        </tr>
                      );
                    })
                    : <tr><td colSpan="6" className="text-center">No record found</td></tr>
                  }
                </tbody>
              </Table>
            </div>

            {total > 0 &&
              <div className="d-flex justify-content-between align-items-center px-3 mb-2">
                <div>
                  <select className="form-select" onChange={limtHandler} value={limit}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount === 1 ? 1 : pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"pagination_prev"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"pagination_next"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            }
          </>
        )
        }
      </div>
    </>
  );
};
export default Payments;
