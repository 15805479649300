import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

const APIURL = process.env.REACT_APP_API_URL_LOCAL

// ** Fetch Users
export const register = createAsyncThunk(
  "user/register",
  async (params) => {
    const response = await axios.post(
      `${APIURL}/users/register`,
      params
    );

    return response.data;
  }
);

export const login = createAsyncThunk(
  "user/login",
  async (params) => {
    const response = await axios.post(
      `${APIURL}/login`,
      params
    );

    return response;
  }
);

export const stats = createAsyncThunk(
  "stats",
  async (params) => {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    };
    const response = await axios.get(
      `${APIURL}/stats`,
      config
    );

    return response.data;
  }
);


export const editProfile = createAsyncThunk(
  "user/editProfile",
  async (params) => {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    };
    const response = await axios.put(
      `${APIURL}/updateProfile`,
      params,
      config
    );

    return response;
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (params) => {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    };
    const response = await axios.post(
      `${APIURL}/changePassword`,
      params,
      config
    );

    return response;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {},
    token: localStorage.getItem('token') ? localStorage.getItem('token') : "",
    userLoggedIn: false,
    Activeusers: 0,
    Inactiveusers: 0,
    Activeposts: 0,
    Inactiveposts: 0,
    Reportedposts: 0,
    todayTotalUsers: 0,
    todayNewusers: 0,
    last30DaysTotalUsers: 0,
    last30DaysNewUsers: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(register.fulfilled, (state, action) => {
      console.log('action', action)
    });

    builder.addCase(login.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.body?.user?.is_admin === 1) {
        state.user = action?.payload?.data?.body?.user;
        state.userLoggedIn = true;
        localStorage.setItem('userData', JSON.stringify(action?.payload?.data?.body?.user))
        localStorage.setItem('token', action?.payload?.data?.body?.token);
      }
    });

    builder.addCase(stats.fulfilled, (state, action) => {
      if (action?.payload?.code === 200) {
        state.Activeusers = action?.payload?.body?.Activeusers;
        state.Inactiveusers = action?.payload?.body?.Inactiveusers;
        state.Activeposts = action?.payload?.body?.Activeposts;
        state.Inactiveposts = action?.payload?.body?.Inactiveposts;
        state.Reportedposts = action?.payload?.body?.Reportedposts;
        state.todayTotalUsers = action?.payload?.body?.todayAnalytics?.totalUsers;
        state.todayNewusers = action?.payload?.body?.todayAnalytics?.newUsers;
        state.last30DaysTotalUsers = action?.payload?.body?.monthlyAnalytics?.totalUsers;
        state.last30DaysNewUsers = action?.payload?.body?.monthlyAnalytics?.newUsers;
      }
    });

    builder.addCase(editProfile.fulfilled, (state, action) => {
      if (action?.payload?.code === 200) {
        state.user = action?.payload?.data?.body?.user;
        localStorage.setItem('userData', JSON.stringify(action?.payload?.data?.body?.user));
      }
    });
  },
});

export default authSlice.reducer;
