import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { stats } from "../../apps/auth";
import axios from 'axios';

const Stats = () => {
  const [realTimeData, setRealtimeData] = useState(null);
  const dispatch = useDispatch();
  const { Activeusers, Inactiveusers, Activeposts, Inactiveposts, Reportedposts, todayTotalUsers, todayNewusers, last30DaysTotalUsers, last30DaysNewUsers } = useSelector((state) => state.auth);

  const data = [
    {
      name: "Active users",
      number: Activeusers,
      icon: "fa fa-user text-white",
      url: "/users"
    },
    {
      name: "Inactive users",
      number: Inactiveusers,
      icon: "fa fa-user-times text-white",
      url: "/users"
    },
    {
      name: "Active posts",
      number: Activeposts,
      icon: "fa fa-clipboard text-white",
      url: "/posts"
    },
    {
      name: "Inactive posts",
      number: Inactiveposts,
      icon: "fa fa-clipboard text-white",
      url: "/posts"
    },
    {
      name: "Today total visitors",
      number: todayTotalUsers,
      icon: "fa fa-users text-white",
    },
    {
      name: "Today new visitors",
      number: todayNewusers,
      icon: "fa fa-user-plus text-white",
    },
    {
      name: "Last 30 days total visitors",
      number: last30DaysTotalUsers,
      icon: "fa fa-users text-white",
    },
    {
      name: "Last 30 days new visitors",
      number: last30DaysNewUsers,
      icon: "fa fa-user-plus text-white",
    },
    {
      name: "Reported posts",
      number: Reportedposts,
      icon: "fa fa-file-text-o text-white",
      url: "/reported-posts"
    },
  ];

  useEffect(() => {
    getStats();
  }, []);

  const getStats = async () => {
    const resp = await dispatch(stats());
  };

  return (
    <>

      {data && data.map((item, i) => {
        return (
          <div className="col-md-3 dashboard_card" key={i}>
            <div className="stats_list  mb-3">
              <div className="stat_box white_box position-relative p-4 h-100 shadow-sm">
                <Link to={item?.url} className="d-flex gap-4 align-items-center">
                  <span className="d-inline-flex justify-content-center align-items-center">
                    <i className={item.icon}></i>
                  </span>
                  <div>
                    <h5 className="mb-1">{item.number}</h5>
                    <p className="mb-0">{item.name}</p>
                  </div>

                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default Stats;
