import React, { useEffect, useState, useMemo } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../common/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetail } from "../../apps/user";
import { fetchPostDetail, fetchPosts, changePage, changeLimit } from "../../apps/post";
import moment from "moment";
import ReactPaginate from "react-paginate";

export default function ViewPost() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const { userDetail } = useSelector((state) => state.user);
    const { posts, total, limit, page, postDetail } = useSelector((state) => state.post);
    const [searchTerm, setSearchTerm] = useState("");
    const [status, setStatus] = useState("");
    const [reported, setReported] = useState("");
    const pageCount = useMemo(() => {
        const pageCounter = Math.ceil(total / limit);
        return pageCounter;
    }, [total, limit]);

    useEffect(() => {
        getUser();
        getPosts();
    }, []);

    const getUser = async () => {
        const resp = await dispatch(fetchUserDetail({
            userId: id
        }));
    };

    const getPosts = async () => {
        const resp = await dispatch(fetchPosts({
            limit: limit,
            page: page,
            user_id: id
        }));
    };

    const handlePageClick = async (data) => {
        await dispatch(changePage(data.selected + 1));
        await dispatch(
            fetchPosts({
                limit: limit,
                page: data.selected + 1,
                user_id: id
            })
        );
    };

    const limitHandler = async (e) => {
        const limit = await dispatch(changeLimit(e.target.value));

        await dispatch(
            fetchPosts({
                limit: e.target.value,
                page: 1,
                user_id: id
            })
        );
    };

    return (
        <div>
            <Sidebar />
            <div className="card">
                <div className="card-header bg-white card-header-wrapper py-3">
                    <h3 className="mb-0">User Detail</h3>
                </div>
                <div className="table-responsive user_detail_card">
                    <Table className="mb-0">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <td>{userDetail?.firstName} {userDetail?.lastName ?? ''}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{userDetail?.email}</td>
                            </tr>
                            <tr>
                                <th>Phone</th>
                                <td>{userDetail?.phone}</td>
                            </tr>
                            <tr>
                                <th>DOB</th>
                                <td>{userDetail?.dob}</td>
                            </tr>
                            <tr>
                                <th>Gender</th>
                                <td>{userDetail?.gender}</td>
                            </tr>
                            <tr>
                                <th>Country</th>
                                <td>{userDetail?.country}</td>
                            </tr>
                            <tr>
                                <th>Education</th>
                                <td>{userDetail?.education}</td>
                            </tr>
                            <tr>
                                <th>Employment</th>
                                <td>{userDetail?.employment}</td>
                            </tr>
                            <tr>
                                <th>Created At</th>
                                <td>{moment(postDetail?.createdAt).format("MMMM DD, YYYY HH:mm A")}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>

            <>
                <div className="card mt-4">
                    <div className="table-responsive">
                        <Table>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Post Type</th>
                                    <th>Likes</th>
                                    <th>Comments</th>
                                    <th>Status</th>
                                    <th>IsReported</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {total > 0 ?
                                    posts.map((item, i) => {
                                        return (
                                            <tr key={item?._id}>
                                                <td>{item?.title}</td>
                                                <td>{item?.postType}</td>
                                                <td>{item?.totalLikes}</td>
                                                <td>{item?.comments?.length}</td>
                                                <td>{item?.status ? 'Active' : 'Inactive'}</td>
                                                <td>{item?.isReported ? 'Yes' : 'No'}</td>
                                                <td>{moment(item?.createdAt).format("MMMM DD, YYYY HH:mm A")}</td>
                                                <td>
                                                    <button
                                                        className="btn action_btn"
                                                        onClick={() => navigate('/post/' + item._id)}
                                                    >
                                                        <i className="fa fa-eye"></i>
                                                    </button>

                                                </td>
                                            </tr>
                                        );
                                    })
                                    : <tr><td colSpan="8">No record found</td></tr>
                                }
                            </tbody>
                        </Table>
                    </div>
                    {total > 0 &&
                        <div className="d-flex justify-content-between align-items-center px-3 mb-2">
                            <div>
                                <select className="form-select" onChange={limitHandler} value={limit}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div>
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={"..."}
                                    pageCount={pageCount === 1 ? 1 : pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"pagination_prev"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"pagination_next"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>
                    }
                </div>
            </>


        </div>
    );
}
