import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// ** Axios Imports
import axios from "axios";

const APIURL = process.env.REACT_APP_API_URL_LOCAL

// ** Fetch Posts
export const fetchPayments = createAsyncThunk(
  "fetchPayments",
  async (params) => {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: params
    };
    const response = await axios.get(
      `${APIURL}/payment/list`,
      config
    );

    return response.data;
  }
);

export const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    payments: [],
    total: 0,
    limit: 10,
    page: 1
  },
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPayments.fulfilled, (state, action) => {
      if (action?.payload?.code === 200) {
        state.payments = action.payload?.body.data;
        state.total = action.payload?.body.count;
      }
    });
  },
});

export const { changeLimit, changePage } = paymentSlice.actions;
export default paymentSlice.reducer;
