import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// ** Axios Imports
import axios from "axios";

const APIURL = process.env.REACT_APP_API_URL_LOCAL

// ** Fetch Posts
export const fetchPosts = createAsyncThunk(
  "fetchPosts",
  async (params) => {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: params
    };
    const response = await axios.get(
      `${APIURL}/post/getPostList`,
      config
    );

    return response.data;
  }
);

export const fetchPostDetail = createAsyncThunk(
  "fetchPostDetail",
  async (params) => {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    };
    const response = await axios.get(
      `${APIURL}/post/view/${params.postId}`,
      config
    );

    return response.data;
  }
);

export const bannedPost = createAsyncThunk(
  "bannedPost",
  async (params) => {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    };
    const response = await axios.patch(
      `${APIURL}/post/bannedPost`, params,
      config
    );

    return response.data;
  }
);

export const postSlice = createSlice({
  name: "post",
  initialState: {
    posts: [],
    total: 0,
    limit: 10,
    page: 1,
    postDetail: {}
  },
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPosts.fulfilled, (state, action) => {
      if (action?.payload?.code === 200) {
        state.posts = action.payload?.body.data;
        state.total = action.payload?.body.count;
      }
    });
    builder.addCase(fetchPostDetail.fulfilled, (state, action) => {
      if (action?.payload?.code === 200) {
        state.postDetail = action.payload?.body.post;
      }
    });
    builder.addCase(bannedPost.fulfilled, (state, action) => {
      if (action?.payload?.code === 200) {
        state.postDetail = action.payload?.body.post;
      }
    });
  },
});

export const { changeLimit, changePage } = postSlice.actions;
export default postSlice.reducer;
