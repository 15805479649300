import React from "react";
import "./layout.css";
import { Routes, Route } from "react-router-dom";
import Home from "../Admin/Home/Home";

import AddUser from "../Admin/User/AddUser";

import Login from "../Admin/Login/Login";

import EditUser from "../Admin/User/EditUser";
import ProtectedRoutes from "../Admin/ProtectedRoutes/ProtectedRoutes";
import Dashboard from "../Admin/Dashboard/Dashboard";
import PageNotFound from "../Admin/PageNotFound/PageNotFound";
import Posts from "../Admin/Post/Posts";
import Users from "../Admin/User/Users";
import Payments from "../Admin/Payment/Payments";
import ViewPost from "../Admin/Post/ViewPost";
import ViewUser from "../Admin/User/ViewUser";
import EditProfile from "../Admin/Dashboard/EditProfile";
import Reported from "../Admin/Post/Reported";

const Routing = () => {
  return (
    <>
      {/* auth routes */}
      <Routes>
        <Route exact={true} path="/login" element={<Login />} />
        <Route exact={true} path="*" element={<PageNotFound />} />

        {/* protected routes */}
        <Route
          exact
          path="/dashboard"
          element={<ProtectedRoutes Component={Dashboard} />}
        />
        {/* user routes */}
        <Route
          exact
          path="/users"
          element={<ProtectedRoutes Component={Users} />}
        />
        <Route
          exact={true}
          path="/users/Add"
          element={<ProtectedRoutes Component={AddUser} />}
        />
        <Route
          exact={true}
          path="/users/Edit"
          element={<ProtectedRoutes Component={EditUser} />}
        />

        <Route
          exact={true}
          path="/"
          element={<ProtectedRoutes Component={Home} />}
        />
        <Route
          exact
          path="/posts"
          element={<ProtectedRoutes Component={Posts} />}
        />
        <Route
          exact
          path="/payments"
          element={<ProtectedRoutes Component={Payments} />}
        />
        <Route
          exact
          path="/post/:id"
          element={<ProtectedRoutes Component={ViewPost} />}
        />
        <Route
          exact
          path="/user/:id"
          element={<ProtectedRoutes Component={ViewUser} />}
        />
        <Route
          exact
          path="/editprofile"
          element={<ProtectedRoutes Component={EditProfile} />}
        />
        <Route
          exact
          path="/reported-posts"
          element={<ProtectedRoutes Component={Reported} />}
        />
      </Routes>
    </>
  );
};
export default Routing;
