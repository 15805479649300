import React, { useEffect } from "react";
import "./common.css";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const removeClass = () => {
    document.getElementById("togglesidebar").classList.remove("active_sidebar");
  };

  return (
    <>

      <div id="sidebar">
        <div className="sidebar_logo mx-auto">

          <img src={require("../assets/images/logo.svg").default} alt="logo" />
          <i className="fa fa-close" onClick={removeClass}></i>

        </div>
        <div className="sidebar_menu">
          <ul className="m-0 p-0">
            <li>
              <Link
                to="/"
                className={location.pathname === "/" ? "active" : ""}
                onClick={removeClass}
              >
                <img
                  src={require("../assets/images/home.svg").default}
                  alt="home"
                />
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/users"
                className={location.pathname === "/users" ? "active" : ""}
                onClick={removeClass}
              >
                <img
                  src={require("../assets/images/users.svg").default}
                  alt="users"
                />
                Users
              </Link>
            </li>
            <li>
              <Link
                to="/posts"
                className={location.pathname === "/posts" ? "active" : ""}
                onClick={removeClass}
              >
                <img
                  src={require("../assets/images/projects.svg").default}
                  alt="posts"
                />
                Posts
              </Link>
            </li>
            <li>
              <Link
                to="/payments"
                className={location.pathname === "/payments" ? "active" : ""}
                onClick={removeClass}
              >
                <img
                  src={require("../assets/images/payments.svg").default}
                  alt="payments"
                />
                Payments
              </Link>
            </li>
            <li>
              <Link
                to="/reported-posts"
                className={location.pathname === "/reported-posts" ? "active" : ""}
                onClick={removeClass}
              >
                <img
                  src={require("../assets/images/clipboard.svg").default}
                  alt="reported-posts"
                />
                Reported Posts
              </Link>
            </li>
            <li>
              <Link
                to="/login"
                className={location.pathname === "" ? "active" : ""}
                onClick={() => {
                  localStorage.removeItem("token");
                  localStorage.removeItem("userData");
                  navigate('/login')
                }}
              >
                <img
                  src={require("../assets/images/logout.png")}
                  alt="help"
                />
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="sidebar_overlay" onClick={removeClass}></div>
    </>
  );
};
export default Sidebar;
