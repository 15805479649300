import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../common/Sidebar";
import { HashLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, changeLimit, changePage, bannedUser, deleteUser } from "../../apps/user";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";

const Users = () => {
  //const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const { users, total, limit, page, isLoading } = useSelector((state) => state.user);
  const pageCount = useMemo(() => {
    const pageCounter = Math.ceil(total / limit);
    return pageCounter;
  }, [total, limit]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const resp = await dispatch(fetchUsers({
      limit: limit,
      page: page,
      keyword: searchTerm
    }));
  };

  const deleteMe = async (id) => {
    try {
      const deleteUser = await axios.delete(
        `${process.env.REACT_APP_API_URL_LOCAL}/users/delete/${id}`
      );

      if (deleteUser.status == 200) {
        toast.success("User deleted");
      }
    } catch (error) {
      if (error.response.status) {
        toast.error("Something went wrong");
      }
    }
  };
  const navigateToAddPage = () => {
    navigate("/users/Add");
  };
  const navigateToEdit = (item) => {
    navigate("/users/Edit", { state: item });
  };
  const handlePageClick = async (data) => {
    await dispatch(changePage(data.selected + 1));
    await dispatch(
      fetchUsers({
        limit: limit,
        page: data.selected + 1,
        keyword: searchTerm
      })
    );
  };

  const limtHandler = async (e) => {
    const limit = await dispatch(changeLimit(e.target.value));

    await dispatch(
      fetchUsers({
        limit: e.target.value,
        page: 1,
        keyword: searchTerm
      })
    );
  };

  const handleSearchOption = async (keyword) => {
    setSearchTerm(keyword);
    await dispatch(
      fetchUsers({
        limit: limit,
        page: 1,
        keyword: keyword,
      })
    );
  };

  const handleStatus = async (userId, status) => {
    const resp = await dispatch(
      bannedUser({
        user_id: userId,
        status: status
      })
    );
    if (resp?.payload?.code === 200) {
      toast.success('Status updated successfully');
      getUsers();
    }
  }

  const deleteItem = async (id) => {
    await swal({
      title: "Are you sure?",
      text: "Once deleted, you can't recover this",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const response = await dispatch(
          deleteUser(id)
        );

        console.log(response)

        if (response?.payload?.code === 200) {
          await dispatch(fetchUsers({
            limit: limit,
            page: page,
            keyword: searchTerm
          }));

          swal({
            title: "Done!",
            text: "deleted",
            icon: "success",
            timer: 2000,
            button: false,
          });
        }
      }
    });
  };

  return (
    <>
      <Sidebar />
      <div className="card">
        <div className="card-header bg-white card-header-wrapper">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="mb-0">Users</h3>

            <div className="d-flex align-items-center justify-content-end gap-4">

              <div className="searchBox">
                <input className="form-control" placeholder="Search" onChange={(e) => handleSearchOption(e.target.value)} value={searchTerm} />
              </div>
              <Button
                className="btn add_btn"
                onClick={navigateToAddPage}
              >
                Add
              </Button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="spinner">
            <HashLoader color="#757575" size={75} loading={true} />
          </div>
        ) : (
          <>

            <div className="table-responsive">
              <Table>
                <thead>
                  <tr>
                    <th>Firstname</th>
                    <th>Lastname</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Country</th>
                    <th>Gender</th>
                    <th>DOB</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {total > 0 ?
                    users.map((item, i) => {
                      return (
                        <tr key={item?._id}>
                          <td>{item?.firstName}</td>
                          <td>{item?.lastName}</td>
                          <td>{item?.email}</td>
                          <td>{item?.phone}</td>
                          <td>{item?.country}</td>
                          <td>{item?.gender}</td>
                          <td>{item?.dob}</td>
                          <td>{item?.status === 1 ? 'Active' : 'Inactive'}</td>
                          <td>
                            <button
                              className="btn action_btn"
                              onClick={() => navigate('/user/' + item._id)}
                            >
                              <i className="fa fa-eye"></i>
                            </button>
                            <button
                              className="btn action_btn p-0"
                              onClick={() => handleStatus(item._id, !item?.status)}
                            >
                              {
                                item?.status ?
                                  <i className="fa fa-ban" title="Click to banned post"></i> :
                                  <i className="fa fa-check" title="Click to remove banned"></i>
                              }
                            </button>
                            <button
                              className="btn action_btn"
                              onClick={() => deleteItem(item?._id)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                    : <tr><td colSpan="9" className="text-center">No record found</td></tr>
                  }
                </tbody>
              </Table>
            </div>
            {total > 0 &&
              <div className="d-flex justify-content-between align-items-center px-3 mb-2">
                <div>
                  <select className="form-select" onChange={limtHandler} value={limit}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount === 1 ? 1 : pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"pagination_prev"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"pagination_next"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            }
          </>
        )}
      </div>

    </>
  );
};
export default Users;
