import React, { useEffect, useState } from "react";
import Sidebar from "../../common/Sidebar";
import { useFormik } from "formik";
import { editProfileSchema, resetPasswordSchema } from "../../validations";
import { useDispatch, useSelector } from "react-redux";
import { editProfile, changePassword } from "../../apps/auth";
import { toast } from "react-toastify";
import { Button, Form } from "react-bootstrap";

const EditProfile = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
        },
        validationSchema: editProfileSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const resp = await dispatch(editProfile(values));
                if (resp.payload.status === 200) {
                    toast.success('Profile updated successfully.');
                } else {
                    toast.error('Some problem exist, Please try again')
                }
            } catch (error) {
                toast.error('Some problem exist, Please try again')
            }
        },
    });

    useEffect(() => {
        formik.setFieldValue('firstName', user?.firstName);
        formik.setFieldValue('lastName', user?.lastName);
        formik.setFieldValue('email', user?.email);
        formik.setFieldValue('phone', user?.phone);
    }, []);

    const formik2 = useFormik({
        initialValues: {
            newPassword: "",
            passwordConfirm: "",
            currentPassword: ""
        },
        validationSchema: resetPasswordSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const resp = await dispatch(changePassword(values));
                if (resp.payload.status === 200) {
                    toast.success('Password changed successfully.');
                } else {
                    toast.error('Some problem exist, Please try again')
                }
            } catch (error) {
                toast.error('Some problem exist, Please try again')
            }
        },
    });

    return (
        <>
            <Sidebar />
            <div className="card">
                <div className="card-header bg-white card-header-wrapper py-3">
                    <h3 className="mb-0">Edit Profile </h3>
                </div>
                <form className="p-3" onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Firstname:</Form.Label>
                                <Form.Control
                                    placeholder="Firstname"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    name="firstName"
                                />
                                {formik.errors.firstName && formik.touched.firstName && (
                                    <div style={{ color: "red" }}>{formik.errors.firstName}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Lastname:</Form.Label>
                                <Form.Control
                                    placeholder="Lastname"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    name="lastName"
                                />
                                {formik.errors.lastName && formik.touched.lastName && (
                                    <div style={{ color: "red" }}>{formik.errors.lastName}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Email:</Form.Label>
                                <Form.Control
                                    placeholder="Email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    name="email"
                                />
                                {formik.errors.email && formik.touched.email && (
                                    <div style={{ color: "red" }}>{formik.errors.email}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Phone:</Form.Label>
                                <Form.Control
                                    placeholder="Phone"
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    name="phone"
                                />
                                {formik.errors.phone && formik.touched.phone && (
                                    <div style={{ color: "red" }}> {formik.errors.phone}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-12 mt-4">
                            <button type="submit" className="px-4 add_btn btn btn-primary">
                                Edit
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div className="card mt-4">
                <div className="card-header bg-white card-header-wrapper py-3">
                    <h3 className="mb-0">Change Password </h3>
                </div>
                <form className="p-3" onSubmit={formik2.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Current Password:</Form.Label>
                                <Form.Control
                                    placeholder="Current Password"
                                    value={formik2.values.currentPassword}
                                    onChange={formik2.handleChange}
                                    name="currentPassword"
                                    type="password"
                                />
                                {formik2.errors.currentPassword && formik2.touched.currentPassword && (
                                    <div style={{ color: "red" }}>{formik2.errors.currentPassword}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>New Password:</Form.Label>
                                <Form.Control
                                    placeholder="New Password"
                                    value={formik2.values.newPassword}
                                    onChange={formik2.handleChange}
                                    name="newPassword"
                                    type="password"
                                />
                                {formik2.errors.newPassword && formik2.touched.newPassword && (
                                    <div style={{ color: "red" }}>{formik2.errors.newPassword}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Confirm password:</Form.Label>
                                <Form.Control
                                    placeholder="Confirm Password"
                                    value={formik2.values.passwordConfirm}
                                    onChange={formik2.handleChange}
                                    name="passwordConfirm"
                                    type="password"
                                />
                                {formik2.errors.passwordConfirm && formik2.touched.passwordConfirm && (
                                    <div style={{ color: "red" }}>{formik2.errors.passwordConfirm}</div>
                                )}
                            </Form.Group>
                        </div>

                        <div className="col-md-12 mt-4">
                            <button type="submit" className="px-4 add_btn btn btn-primary">
                                Edit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default EditProfile;