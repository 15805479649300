import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../common/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { fetchPostDetail } from "../../apps/post";
import moment from "moment";

export default function ViewPost() {
    const dispatch = useDispatch();
    const { postDetail } = useSelector((state) => state.post);
    const { id } = useParams();

    useEffect(() => {
        getPost();
    }, []);

    const getPost = async () => {
        const resp = await dispatch(fetchPostDetail({
            postId: id
        }));
    };

    return (
        <div>
            <Sidebar />
            <div className="card">
                <div className="card-header bg-white card-header-wrapper py-3">
                    <h3 className="mb-0">Post</h3>
                </div>
                <div className="table-responsive user_detail_card">
                    <Table className="mb-0">
                        <tbody>
                            {
                                postDetail?.postType !== 'general' &&
                                <tr>
                                    <th>Title</th>
                                    <td>{postDetail?.title}</td>
                                </tr>
                            }
                            <tr>
                                <th>Post Type</th>
                                <td>{postDetail?.postType}</td>
                            </tr>
                            {
                                postDetail?.postType === 'job' &&
                                <>
                                    <tr>
                                        <th>Opening</th>
                                        <td>{postDetail?.opening}</td>
                                    </tr>
                                    <tr>
                                        <th>Salary</th>
                                        <td>{postDetail?.salary}</td>
                                    </tr>
                                </>
                            }
                            {
                                postDetail?.postType === 'webinar' &&
                                <tr>
                                    <th>Link</th>
                                    <td>{postDetail?.link}</td>
                                </tr>
                            }

                            <tr>
                                <th>Status</th>
                                <td>{postDetail?.status ? 'Active' : 'Inactive'}</td>
                            </tr>
                            <tr>
                                <th>Description</th>
                                <td>{postDetail?.description}</td>
                            </tr>
                            <tr>
                                <th>Reported</th>
                                <td>{postDetail?.isReported ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <th>Created By</th>
                                <td>{postDetail?.user?.firstName} {postDetail?.user?.lastName ?? ''}</td>
                            </tr>

                            <tr>
                                <th>Likes</th>
                                <td>{postDetail?.totalLikes}</td>
                            </tr>
                            <tr>
                                <th>Comments</th>
                                <td>{postDetail?.comments?.length}</td>
                            </tr>
                            <tr>
                                <th>Attachments</th>
                                <td>{
                                    postDetail?.attachments?.length > 0 && postDetail.attachments.map((item) => {
                                        return (
                                            <>
                                                <p>{item.filetype.includes('image') && <img src={`${process.env.REACT_APP_API_URL_LOCAL}/uploads/post/${item?.filename}`} />}</p>
                                                <p>{item.filetype.includes('video') && <video src={`${process.env.REACT_APP_API_URL_LOCAL}/uploads/post/${item?.filename}`} />}</p>
                                            </>
                                        )
                                    })
                                }</td>
                            </tr>
                            <tr>
                                <th>Created At</th>
                                <td>{moment(postDetail?.createdAt).format("MMMM DD, YYYY HH:mm A")}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>


        </div>
    );
}
