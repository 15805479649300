import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// ** Axios Imports
import axios from "axios";

const APIURL = process.env.REACT_APP_API_URL_LOCAL

// ** Fetch Users
export const fetchUsers = createAsyncThunk(
  "fetchUsers",
  async (params) => {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: params
    };
    const response = await axios.get(
      `${APIURL}/getAllUsers`,
      config
    );

    return response.data;
  }
);

export const addUser = createAsyncThunk(
  "addUser",
  async (params) => {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    };
    const response = await axios.post(
      `${APIURL}/signup`, params,
      config
    );

    return response;
  }
);

export const bannedUser = createAsyncThunk(
  "bannedUser",
  async (params) => {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    };
    const response = await axios.patch(
      `${APIURL}/bannedUser`, params,
      config
    );

    return response.data;
  }
);

export const fetchUserDetail = createAsyncThunk(
  "fetchUserDetails",
  async (params) => {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: params
    };
    const response = await axios.get(
      `${APIURL}/getSearchPeopleprofile/${params.userId}`,
      config
    );

    return response.data;
  }
);

export const deleteUser = createAsyncThunk(
  "deleteUser",
  async (userId) => {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    };
    const response = await axios.delete(
      `${APIURL}/delete/${userId}`,
      config
    );

    return response.data;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    users: [],
    total: 0,
    limit: 10,
    page: 1,
    isLoading: true,
    userDetail: {}
  },
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.isLoading = true;
      if (action?.payload?.code === 200) {
        state.users = action.payload?.body.users;
        state.total = action.payload?.body.total;
        state.isLoading = false;
      }
    });

    builder.addCase(fetchUserDetail.fulfilled, (state, action) => {
      state.isLoading = true;
      if (action?.payload?.code === 200) {
        state.userDetail = action.payload?.body;
      }
    });
  },
});

export const { changeLimit, changePage } = userSlice.actions;
export default userSlice.reducer;
